import { Typography } from 'antd';
import Button from '../components/Elements/Button';
import { api_url, instance, setNewToken } from '../helpers/fetcher';
import nookies from 'nookies';
import { useContext, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { UserContext } from '../contexts/UserContext';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { AlertOutlined, GoogleOutlined } from '@ant-design/icons';
import { i18n } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { PulppoLoader } from '../components/Elements/PulppoLoader';
import { useTranslate } from '@tolgee/react';
import { GetServerSideProps } from 'next';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslate('login');

    const [disabled, setDisabled] = useState(false);
    const { setUser, logout } = useContext(UserContext);
    // const [error, setError] = useState('');
    const router = useRouter();
    const googleLogin = useGoogleLogin({
        flow: 'auth-code',
        // hosted_domain: process.env.NEXT_PUBLIC_HOSTED_DOMAIN || 'pulppo.com',
        hint: router.query?.hint as string,
        select_account: true,
        enable_serial_consent: false,
        scope: 'profile email https://www.googleapis.com/auth/calendar https://mail.google.com/ https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/youtube',
        onSuccess: async (codeResponse) => {
            const auth = getAuth();
            // setError('');
            router.query.message = '';
            router.push(router);
            setLoading(true);
            await logout(false).catch(() => {
                console.error('Could not logout');
            });
            try {
                const res = await instance
                    .post(`${api_url}/login`, {
                        code: codeResponse.code
                    })
                    .then((res) => res.data);
                // const credential = GoogleAuthProvider.credential(res.id_token, res.access_token);
                // const { user } = await signInWithCredential(auth, credential);
                const { user } = await signInWithCustomToken(auth, res.firebase_token);
                nookies.set(undefined, 'currentUser', user?.uid, {
                    path: '/',
                    sameSite: 'None',
                    secure: true,
                    expires: new Date(2147483647000)
                });
                const token = await user.getIdToken(true);

                const userFromApi = await instance
                    .post(
                        `${api_url}/agent`,
                        {
                            createdAt: new Date(user.metadata.creationTime).getTime(),
                            lastLogin: new Date(user.metadata.lastSignInTime).getTime(),
                            email: user.email,
                            uid: user.uid,
                            token
                        },
                        {
                            headers: {
                                'x-access-token': token
                            }
                        }
                    )
                    .then((res) => res.data);

                // const userFromApi: IAgent = await fetcher(`${api_url}/agent/${uid}`);
                setNewToken(userFromApi.accessToken);
                setUser({
                    ...userFromApi,
                    name: `${userFromApi.firstName} ${userFromApi.lastName}`
                });
                const path = router.query?.redirect && decodeURIComponent(router.query?.redirect as string);
                await router.push(path || '/');
            } catch (error) {
                router.query.message = error?.response?.data?.error || error.message;
                router.push(router);
                nookies.destroy(undefined, 'currentUser');
                setUser(null);
            } finally {
                setLoading(false);
            }
        },
        onError: (errorResponse) => {
            nookies.destroy(undefined, 'currentUser');
            setUser(null);
            if (errorResponse.error_description == 'idpiframe_initialization_failed') {
                setDisabled(true);
                router.query.message = `Pulppo no anda en una ventana de incógnito`;
                router.push(router);
            } else {
                // setError(errorResponse.error_description);
                router.query.message = errorResponse.error_description;
                router.push(router);
            }
        }
    });

    return (
        <div className="flex min-h-screen w-full flex-col items-stretch bg-white lg:flex-row">
            <div className="relative w-full shrink-0 lg:w-1/2">
                <img src="/register_bg.png" className="absolute left-0 top-0 h-full w-full" alt="City" />
                <div className="absolute left-0 top-0 z-10 h-full w-full bg-gradient-to-t from-black to-transparent" />
                <div className="relative z-20 flex h-full w-full flex-col items-center justify-center gap-6 p-12 lg:p-20 2xl:gap-20">
                    <img
                        src={process.env.NEXT_PUBLIC_LOGO_BLANCO_SOLO || '/pulppo-icon-white.png'}
                        alt="Pulppo"
                        className="h-[177px] w-[177px]"
                    />
                    <h1 className="w-full text-[32px] text-white 2xl:text-[51px]">{t('login_title')}</h1>
                    <h5 className="w-full text-base text-white 2xl:text-[22px]">{t('login_description')}</h5>
                </div>
            </div>
            <div className="flex w-full shrink-0 flex-col items-center justify-center gap-20 overflow-y-auto p-12 lg:w-1/2 2xl:px-40">
                <PulppoLoader loading={loading}>
                    <div className="flex w-full shrink-0 flex-col items-center justify-center gap-20 overflow-y-auto">
                        <div className="3xl:px-40 flex shrink-0 flex-col items-center gap-4">
                            <h3 className="text-center text-[26px] font-bold">{t('login_welcome')}</h3>
                            <h6 className="px-6 text-center text-base">
                                {t('login_t_&_c')}{' '}
                                <Link
                                    target="_blank"
                                    locale={false}
                                    href={process.env.NEXT_PUBLIC_PRIVACY || 'https://pulppo.com/privacy'}
                                >
                                    <span className="cursor-pointer font-medium text-black">{t('privacy_policy')}</span>
                                </Link>{' '}
                                {t('and', 'y')}{' '}
                                <Link
                                    target="_blank"
                                    locale={false}
                                    href={process.env.NEXT_PUBLIC_TERMS || 'https://pulppo.com/terms'}
                                >
                                    <span className="cursor-pointer font-medium text-black">{t('t_&_c')}</span>
                                </Link>
                            </h6>
                        </div>
                        {router.query.message && (
                            <p className="flex w-full items-center gap-4 bg-pulppo-status-light-error px-2 py-1 text-pulppo-status-dark-error">
                                <AlertOutlined className="text-pulppo-status-dark-error" />
                                {router.query.message}
                            </p>
                        )}
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                googleLogin();
                            }}
                            type="secondary"
                            className="w-full bg-pulppo-primary-black py-4 text-sm font-light text-white"
                        >
                            <GoogleOutlined color="#ff0000" /> {t('login_button')}
                        </Button>

                        <div className="mt-20px text-center">
                            <div className="font-thin">{t('join_caption')} </div>
                            <div>
                                <Typography.Link
                                    className="font-medium text-black"
                                    href="https://pulppo.com/broker/#AgentForm"
                                >
                                    {t('join_button')}
                                </Typography.Link>
                            </div>
                        </div>
                    </div>
                </PulppoLoader>
            </div>
        </div>
    );
};

const WrappedLogin = () => {
    return (
        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
            <Login></Login>
        </GoogleOAuthProvider>
    );
};

export default WrappedLogin;

export const getServerSideProps: GetServerSideProps = async (ctx) => {
    const { locale } = ctx;
    if (process.env.NODE_ENV === 'development') {
        await i18n?.reloadResources();
    }
    const cookies = nookies.get(ctx);
    const newtoken = cookies['new-token'];
    const oldtoken = cookies.token;
    console.log(`${api_url}/agent/verify/${cookies.currentUser}`);
    const data = await fetch(`${api_url}/agent/verify/${cookies.currentUser}`, {
        headers: { Authorization: `Bearer ${oldtoken || newtoken}` }
    })
        .then((res) => res.json())
        .catch((err) => null);
    if (data?.valid) {
        return {
            redirect: {
                permanent: false,
                destination: (ctx.query.redirect as string) || '/'
            }
        };
    }

    return {
        props: {
            ...(await serverSideTranslations(locale, ['common']))
        }
    };
};
